import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';


import LoginWithNostr from '../components/User/LoginWithNostr';
import LoginWithEmail from '../components/User/LoginWithEmail';
import SignUpWithEmail from '../components/User/SignUpWithEmail';
import SignUpWithNostr from '../components/User/SignUpWithNostr';

function LoginPage() {
  const [useEmailLogin, setUseEmailLogin] = useState(true);
  const [viewingSignup, setViewingSignup] = useState(false); // New state to control login or signup view
  const [referralPublicKey, setReferralPublicKey] = useState ();

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const referral = queryParams.get('referral');
    if (referral) {
      setReferralPublicKey(referral);
    }
  }, [location.search]);

  const handleResetPasswordClick = () => {
    navigate('/password-reset-request'); // Use the path you defined for the password reset page
  };

  const toggleLoginMethod = () => {
    setUseEmailLogin(!useEmailLogin);
  };

  const toggleSignupView = () => {
    setViewingSignup(!viewingSignup);
  };

  useEffect (() => {
    const queryParams = new URLSearchParams(window.location.search);
    const referralUserPublickey = queryParams.get('ref');
    setReferralPublicKey (referralUserPublickey)
  }, [window.location]);

  return (
    <div className="flex h-5/6 h-auto md:h-screen" style={{backgroundImage: "url('nimages/bg-flash.png')", backgroundPosition: 'top', backgroundSize: 'contain', backgroundRepeat: 'no-repeat'}}>
      {/* Left Column for Login Form */}
      <div className="flex flex-col justify-center pt-4 md:pt-0  px-4 md:px-36 text-white w-full align-middle items-center " >
        <div className="w-full md:w-2/3 lg:w-2/3 xl:w-1/3  border-slate-200 rounded-md p-7 pb-4 shadow-lg bg-white">
          <img className="w-40 mb-3 -ml-1 -mt-0.5" src="/nimages/logo-org.png"></img>  {/* This div will align all enclosed text to the left */}
          {!viewingSignup && (
            <>
              {useEmailLogin ? (
                <>
                  <LoginWithEmail />
                  <button
                    onClick={toggleLoginMethod}
                    className="mt-1.5 flex w-full justify-center rounded-md bg-indigo-600 px-3 py-3 text-sm font-semibold leading-6 text-white border border-slate-200  hover:bg-indigo-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
                  >
                    Continue with Nostr
                  </button>

                  <p className="text-center mt-4 text-sm text-slate-900">
                    Don't have an account?{' '}
                    <button onClick={toggleSignupView} className="font-semibold leading-6 text-indigo-800 hover:text-indigo-600 focus:outline-none">
                      Sign up!
                    </button>
                  </p>
                  <p className="text-center">
                    <button onClick={handleResetPasswordClick} className=" mt-1 text-sm leading-6 text-slate-500 hover:text-slate-600 focus:outline-none">
                      Lost your password?
                    </button>
                  </p>
                </>
              ) : (
                <>
                  <LoginWithNostr />
                  <button
                    onClick={toggleLoginMethod}
                    className="mt-1.5 flex w-full justify-center rounded-md bg-white px-3 py-3 text-sm font-semibold leading-6 text-indigo-800 border border-slate-200  hover:bg-slate-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
                  >
                    Continue with Email
                  </button>
                  <p className="text-center mt-4 text-sm text-slate-900">
                    Don't have an account?{' '}
                    <button onClick={toggleSignupView} className="font-semibold text-sm leading-6 text-purple-500 hover:text-purple-400 focus:outline-none">
                      Sign up!
                    </button>
                  </p>
                </>
              )}
            </>
          )}

          {viewingSignup && (
            <>
              {useEmailLogin ? (
                <>
                  <SignUpWithEmail referralPublicKey={referralPublicKey} />
                  <button
                    onClick={toggleLoginMethod}
                    className="mt-1.5 flex w-full justify-center rounded-md bg-indigo-600 px-3 py-3 text-sm font-semibold leading-6 text-white border border-slate-200  hover:bg-indigo-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
                  >
                    Continue with Nostr
                  </button>
                  <p className="text-center mt-5 text-sm text-slate-900">
                    Already have an account?{' '}
                    <button onClick={toggleSignupView} className="font-semibold leading-6 text-indigo-800 hover:text-indigo-600 focus:outline-none">
                      Log in!
                    </button>
                  </p>
                </>
              ) : (
                <>
                  <SignUpWithNostr referralPublicKey={referralPublicKey} />
                  <p className="text-center mt-4 text-sm text-slate-900">
                    Already have an account?{' '}
                    <button onClick={toggleSignupView} className="font-semibold leading-6 text-purple-500 hover:text-purple-400 focus:outline-none">
                      Log in!
                    </button>
                  </p>
                </>
              )}
            </>
          )}
        </div>

        <div className="ml-4 text-xs credits-login">
          Flash Lightning Solutions LTD <br></br>
          2024 © All Rights Reserved 
        </div>
      </div>
    </div>
  );
}

export default LoginPage;
