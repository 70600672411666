import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import { connectToRelays } from './components/NostrEggs';
import RoutesWrapper from './components/RoutesWrapper';

import { Provider } from 'react-redux';
import store from './Redux/store';

function App() {
  const [relayRefs, setRelayRefs] = useState(null);
  const relayUrls = ["wss://relay.paywithflash.com"];

  useEffect(() => {
    const initializeRelayRefs = async () => {
      const refs = await connectToRelays(relayUrls);
      setRelayRefs(refs);
    };

    initializeRelayRefs();
  }, []); 


  return (
    <Provider store = {store}>
      <Router>
        <RoutesWrapper />
        <div className="App">
        </div>
      </Router>
    </Provider>
  );  
}

export default App;
