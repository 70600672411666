import React, { useState } from 'react';
import { SimplePool, Kind, finishEvent } from 'nostr-tools';

export function UserProfile() {
  let userHexPrivateKey = sessionStorage.getItem('userHexPrivateKey') || "";
  let userHexPublicKey = sessionStorage.getItem('userHexPublicKey') || "";
  let userNpub = sessionStorage.getItem('userNpub') || "";
  let userEmail = sessionStorage.getItem('userEmail') || "";
  const relayUrls = ["wss://relay.paywithflash.com", "wss://relay.damus.io", "wss://relay.primal.net", "wss://relay.snort.social", "wss://relay.nostr.band"];


  // Convert to state variables for editing
  const [userName, setUserName] = useState(localStorage.getItem('userName') || "");
  const [userAbout, setUserAbout] = useState(localStorage.getItem('userAbout') || "");
  const [userPictureUrl, setUserPictureUrl] = useState(localStorage.getItem('userPictureUrl') || "");
  
  const [selectedTab, setSelectedTab] = useState('Profile');
  // State for image file
  const [imageFile, setImageFile] = useState(null);
  // State to track if the avatar has been updated
  const [avatarUpdated, setAvatarUpdated] = useState(false);


  // Function to set user metadata
  async function publishUserMetadata() {
    // Metadata object created from state variables
    const metadata = { name: userName, picture: userPictureUrl, about: userAbout };
  
    // Initialize the pool
    const pool = new SimplePool();
  
    // Create a metadata event
    const metadataEvent = {
      kind: Kind.Metadata,
      tags: [],
      content: JSON.stringify(metadata),
      created_at: Math.floor(Date.now() / 1000), // Current timestamp in seconds
      pubkey: userHexPublicKey,
      id: '', // The ID will be calculated in finishEvent
      sig: '', // The signature will be added in finishEvent
    };

  
    // Sign the event
    const signedEvent = finishEvent(metadataEvent, userHexPrivateKey);
  
    // Publish the event to the list of relays
    try {
      const publishPromises = pool.publish(relayUrls, signedEvent);
      await Promise.all(publishPromises);
      console.log('Metadata event published successfully to all relays.');
    } catch (error) {
      console.error('An error occurred while publishing the event:', error);
    }
  }


  // Handle file change and upload
  const handleFileChangeAndUpload = async (e) => {
    const file = e.target.files[0];
    if (!file) {
      return; // No file selected
    }

    setImageFile(file);

    // Create FormData object to send files
    const formData = new FormData();
    formData.append('image', file);

    // Send data to your backend server
    const response = await fetch('https://api.paywithflash.com/api/upload_user_profile_picture', {
        method: 'POST',
        body: formData,
    });

    const data = await response.json();
    if (data.success) {
        // console.log('Image uploaded successfully:', data.url);
        setUserPictureUrl(data.url); // Immediately update the profile picture in the interface
        localStorage.setItem('userPictureUrl', data.url);
        setAvatarUpdated(true); // Set avatarUpdated to true when new avatar is uploaded
    } else {
        console.error('Failed to upload image');
    }
  };


  // Handle form submission
  const handleSaveProfile = async (e) => {
    e.preventDefault();

    // Update session storage with the new profile information
    localStorage.setItem('userName', userName);
    localStorage.setItem('userAbout', userAbout);

    // Call publishUserMetadata to update the user profile
    await publishUserMetadata();

    console.log('Profile updated successfully');
    setAvatarUpdated(false); // Reset avatarUpdated to false after saving
  };

  return (

    <div className="grid grid-cols-1 md:grid-cols-4 gap-x-8 gap-y-10">
        <div className='md:col-span-1 pt-1'>
            <h2 className="text-xl font-semibold leading-7 text-indigo-900">Profile Information</h2>
            <p className="text-sm leading-6 text-indigo-900 opacity-70">Manage your personal information.</p>
        </div>
        <div className="md:col-span-3">
            {/* Profile form content */}
            <div className="overflow-hidden rounded-lg bg-white shadow p-8 mr-12">
                <form className="space-y-6 mr-2" onSubmit={handleSaveProfile}>
                    <div className="flex items-top gap-x-8">
                        <img
                        className="h-24 w-24 rounded-full bg-gray-800 object-cover"
                        src={userPictureUrl}
                        alt={userName}
                        />
                        <div>
                            <input
                                type="file"
                                id="image"
                                accept="image/*"
                                onChange={handleFileChangeAndUpload}
                                style={{ display: "none" }} // Hide the default file input
                            />
                            <button 
                                type="button" 
                                className="rounded-md bg-indigo-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-600"
                                onClick={() => document.getElementById('image').click()}
                            >
                                Change avatar
                            </button>
                            <p className="mt-1 text-xs leading-5 text-gray-400">JPG, GIF or PNG. 1MB max.</p>
                            {avatarUpdated && (
                                <p className="text-sm text-red-500">
                                Click on Save to update your avatar
                                </p>
                            )}
                        </div>
                    </div>
                    <div>
                        <label htmlFor="username" className="block text-sm font-medium text-gray-700">Username</label>
                        <input
                            type="text"
                            id="username"
                            value={userName}
                            onChange={(e) => setUserName(e.target.value)}
                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        />
                    </div>
                    <div>
                        <label htmlFor="bio" className="block text-sm font-medium text-gray-700">Bio</label>
                        <textarea
                            id="bio" 
                            rows={3} 
                            value={userAbout}
                            onChange={(e) => setUserAbout(e.target.value)}
                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        ></textarea>
                    </div>
                    { userEmail !== "null" && (
                        <div>
                            <label htmlFor="email" className="block text-sm font-medium text-gray-700">Email</label>
                            <input
                                disabled={true}
                                type="text"
                                id="email"
                                value={userEmail || ''}  // Set to empty string if userEmail is null
                                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                            />
                        </div>
                    )}

                    <div>
                        <button type="submit" className="inline-flex justify-center rounded-md border border-transparent bg-indigo-500 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-600 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">Save</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
  );
}
