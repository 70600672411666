import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getPublicKey, generatePrivateKey, nip19 } from 'nostr-tools';
import { EyeIcon as FaEye } from '@heroicons/react/24/outline';

const { npubEncode, nsecEncode } = nip19;

const convertHexToNostr = (hex, type) => {
  if (type === 'npub') {
    return npubEncode(hex);
  } else if (type === 'nsec') {
    return nsecEncode(hex);
  } else {
    throw new Error('Invalid type. Type must be "npub" or "nsec".');
  }
};

const SignUpWithNostr = ({ referralPublicKey }) => {
  const [privateKey, setPrivateKey] = useState('');
  const [showPrivateKey, setShowPrivateKey] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();

  const handleCreatePrivateKey = async (e) => {
    e.preventDefault();
    setLoading(true);
    setErrorMessage('');

    try {
      const newPrivateKey = generatePrivateKey();
      const publicKey = getPublicKey(newPrivateKey);
      const nsec = convertHexToNostr(newPrivateKey, 'nsec');
      const npub = convertHexToNostr(publicKey, 'npub');
      
      // Store keys in session storage
      localStorage.setItem('userHexPrivateKey', newPrivateKey);
      localStorage.setItem('userHexPublicKey', publicKey);
      localStorage.setItem('userNsec', nsec);
      localStorage.setItem('userNpub', npub);

      // Prepare data for backend
      const loginOrigin = window.location.origin;

      const response = await fetch('https://api.paywithflash.com/api/signup_with_nostr', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: new URLSearchParams({
          public_key: publicKey,
          referral_public_key: referralPublicKey == null ? '' : referralPublicKey, // Pass the referral public key
          login_origin: loginOrigin
        })
      });

      if (response.ok) {
        navigate('/dashboard');
      } else {
        const data = await response.json();
        setErrorMessage(data.error || 'Signup failed. Please try again.');
      }
    } catch (error) {
      setErrorMessage('An error occurred during signup. Please try again.');
      console.error('Signup error:', error);
    } finally {
      setLoading(false);
    }
  };

  const togglePrivateKeyVisibility = (e) => {
    e.preventDefault();
    setShowPrivateKey(!showPrivateKey);
  };

  return (
    <>
      {loading && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
          <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12 mb-4"></div>
        </div>
      )}
      <div className="flex flex-col">
        <div className="flex items-center mb-0">
          <h2 className="text-left text-xl text-slate-900 font-bold leading-9 tracking-tight login-email">
            Create new Nostr keys.
          </h2>
        </div>
        <p className='text-slate-700 mt-4'><b>Important notice:</b> Nostr gives more privacy, but comes with responsibility: we cannot recover your account. Save your keys in a secure location.</p>
        <a
          href="#"
          onClick={handleCreatePrivateKey}
          className="mt-8 flex w-full justify-center rounded-md bg-white px-3 py-3 text-sm font-semibold leading-6 text-purple-800 border border-slate-200 hover:bg-slate-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
        >
          <img src="/nimages/nostr-icon.png" className='w-5 h-5 mr-2 mt-0.5' alt="Nostr icon" />
          Create new Nostr keys!
        </a>
        {errorMessage && <div className="text-red-500 mt-2 text-sm">{errorMessage}</div>}
      </div>
    </>
  );
};

export default SignUpWithNostr;
