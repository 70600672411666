import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { ChevronRightIcon } from '@heroicons/react/20/solid'
import CountdownTimer from './CountdownTimer'

import { BiLink, BiSolidLockAlt } from 'react-icons/bi';
import { BsFillCameraVideoOffFill, BsFillPersonVcardFill } from 'react-icons/bs';
import { LuMousePointerClick } from 'react-icons/lu';



// Function to get the price of Bitcoin
async function getBitcoinPrice() {
  try {
      const response = await fetch('https://api.paywithflash.com/api/get_bitcoin_price', {
          method: 'POST', // if the API requires POST method
          headers: {
              'Content-Type': 'application/json',
          },
          // If the API requires a body payload, uncomment and edit the following line
          // body: JSON.stringify({ key: 'value' }), 
      });

      if (!response.ok) {
          // If the response is not OK, throw an error with the response status
          throw new Error(`API call failed with status: ${response.status}`);
      }

      const data = await response.json();

      // Assuming the API returns an object with a property that contains the Bitcoin price
      return data
  } catch (error) {
      console.error('API call failed:', error);
      throw error; // Rethrow the error to be handled by the caller
  }
}

// Function to go through the subscription and make sure we have two columns: price in sats & price in dollars
async function convertPrices(subscriptions) {
  try {
      const bitcoinPrice = await getBitcoinPrice(); // Assuming this function fetches the current price of Bitcoin in dollars
      const oneBitcoinInSats = 100000000; // 100 million satoshis in one bitcoin

      return subscriptions.map(subscription => {
          let priceInDollars, priceInSats;

          if (subscription.membership_details.currency === "Dollars") {
              priceInDollars = parseFloat(subscription.membership_details.price);
              priceInSats = Math.round((priceInDollars / bitcoinPrice) * oneBitcoinInSats);
          } else if (subscription.membership_details.currency === "Satoshis") {
              priceInSats = parseFloat(subscription.membership_details.price);
              priceInDollars = ((priceInSats / oneBitcoinInSats) * bitcoinPrice).toFixed(2);
          }

          return {
              ...subscription,
              priceInDollars,
              priceInSats
          };
      });
  } catch (error) {
      console.error('Conversion failed:', error);
      return subscriptions; // Return original subscriptions in case of error
  }
}

// Get the next payment date based on the membershi renewal interval
function getNextRenewalDateISOString(renewalInterval) {
  const currentDate = new Date();

  switch (renewalInterval) {
      case "Yearly":
          currentDate.setFullYear(currentDate.getFullYear() + 1);
          break;
      case "Monthly":
          currentDate.setMonth(currentDate.getMonth() + 1);
          break;
      case "Weekly":
          currentDate.setDate(currentDate.getDate() + 7);
          break;
      default:
          throw new Error("Invalid renewal interval");
  }

  return currentDate.toISOString();
}







const renderStatus = (status) => {
  let bgColor, textColor, text;
  switch (status) {
    case 'active':
      bgColor = 'bg-emerald-500';
      textColor = 'text-gray-500';
      text = 'Active';
      break;
    case 'paused':
      bgColor = 'bg-yellow-500';
      textColor = 'text-gray-500';
      text = 'Paused';
      break;
    case 'inactive':
      bgColor = 'bg-red-500';
      textColor = 'text-gray-500';
      text = 'Expired';
      break;
    default:
      bgColor = 'bg-gray-500';
      textColor = 'text-gray-500';
      text = 'Unknown';
  }

  return (
    <div className="mt-1 flex items-center gap-x-1.5">
      <div className={`flex-none rounded-full ${bgColor}/20 p-1`}>
        <div className={`h-1.5 w-1.5 rounded-full ${bgColor}`} />
        </div>
      <p className={`text-xs leading-5 ${textColor}`}>{text}</p>
    </div>
  );
}



export default function UserSubscriptionsList({ subscriptions, userHexPublicKey, fetchSubscriptions }) {
  // Get stored variables
  // let userHexPrivateKey = sessionStorage.getItem('userHexPrivateKey') || "";
  // let userHexPublicKey = sessionStorage.getItem('userHexPublicKey') || "";
  // let userNpub = sessionStorage.getItem('userNpub') || "";
  let userName = localStorage.getItem('userName') || "";
  let userAbout = localStorage.getItem('userAbout') || "";
  let userPictureUrl = localStorage.getItem('userPictureUrl') || "";

  const [selectedSubscription, setSelectedSubscription] = useState(null);
  const [updatedSubscriptions, setUpdatedSubscriptions] = useState([]);
  const navigate = useNavigate();

  const handleFlashSelect = (subscription) => {
    // console.log("Do something")
    // navigate(`/subscription-details/${subscription.id}`, { state: { subscription } });
  };
  

  function handleDetailsClick(event) {
    event.stopPropagation();
  }



  function handleRenewSubscription(event, subscription) {
    event.stopPropagation();
  
    // Recursive function to search for "vimeo_ott" in keys
    function searchForVimeoOttKey(obj) {
      for (const key in obj) {
        if (key.includes("vimeo_ott")) {
          return true;
        }
        // If the value is an object, search its keys too
        if (typeof obj[key] === 'object' && obj[key] !== null) {
          const found = searchForVimeoOttKey(obj[key]);
          if (found) {
            return true; // "vimeo_ott" found in sub-keys
          }
        }
      }
      return false; // "vimeo_ott" not found
    }
  
    // Use the recursive function to check the subscription
    if (searchForVimeoOttKey(subscription)) {
      window.open(`/external-login/vimeo_ott?flashId=${subscription.flash_details.id}`);
      return; // Exit the function after redirecting
    }
  
    // If no "vimeo_ott" key or sub-key found, continue with the default behavior
    window.open(`/external-login?flashId=${subscription.flash_details.id}`);
  
    // ORRRRRR..
    // Get the nwc from the flash owner
    // Get the nwc from the user
    // Create an invoice with the nwc of the flash own
    // Pay this invoice with the nwc of the user
    // Update the user membership using the user_membership_id
      // Update fields status to 'active', paid to 'true', next_payment_date to 'current date + renewal_interval'
    // Save the transaction to the db
  }


  async function handleResumeSubscription(event, subscription) {
    event.stopPropagation();

    // Get current date and next payment date
    const nextRenewalDate = getNextRenewalDateISOString(subscription.membership_details.renewal_intervals);

    try {
        const response = await fetch('https://api.paywithflash.com/api/update_user_membership', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: new URLSearchParams({
                user_membership_id: subscription.user_membership_details.id,
                user_public_key: subscription.user_membership_details.user_public_key,
                flash_id: subscription.user_membership_details.flash_id,
                external_uuid: subscription.user_membership_details.external_uuid,
                membership_id: subscription.user_membership_details.membership_id,
                wallet_id: subscription.user_membership_details.wallet_id,
                is_paid: subscription.user_membership_details.is_paid,
                membership_start_date: subscription.user_membership_details.membership_start_date,
                next_payment_date: subscription.user_membership_details.next_payment_date,
                status: 'active'
            }),
        });
  
        const data = await response.json();
  
        if (!response.ok) {
            throw new Error(data.error || 'Failed to update membership');
        }
  
        // After successfully deleting, call fetchSubscriptions from props
        const updatedSubscriptions = await fetchSubscriptions(userHexPublicKey);
        if (updatedSubscriptions) {
          setUpdatedSubscriptions(updatedSubscriptions);
        }
    } catch (error) {
        console.error('Error while updating membership:', error);
        throw error;
    }
  }
  
  // Function to send the subscription info via webhook
  async function sendPayInfoViaWebhook (flashId, userPublicKey, plan, userName, userAbout, userPictureUrl, eventType, signUpDate, failedPaymentDate, transactionId, transactionDate) {

    const payload = {
      eventType: eventType,
      flashId: flashId,
      userPublicKey: userPublicKey,
      plan: plan,
      userName: userName,
      userAbout: userAbout,
      userPictureUrl: userPictureUrl,
      signUpDate: signUpDate,
      failedPaymentDate: failedPaymentDate,
      transactionId: transactionId,
      transactionDate: transactionDate
    };
  
    try {
      const response = await fetch('https://api.paywithflash.com/api/send_payinfo_via_webhook', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! status : ${response.status}`);
      }
    } catch (err) {
      console.log("Error sending pay info to Geyser", err);
      throw err;
    }
  }

  // Function that calls the API to update the status of the user_subscription to "paused"
  async function handlePauseSubscription(event, subscription, userHexPublicKey) {
    event.stopPropagation();

    console.log(subscription)

    let apiEndpoint;
    // Check for Vimeo OTT Subscription
    if (subscription.flash_details.name === "Vimeo OTT Subscription") {
      apiEndpoint = 'https://api.paywithflash.com/vimeo_ott/api/set_vimeo_ott_user_membership_paused';
    } 
    // Check for Geyser Subscription
    else if (subscription.flash_details.name === "Geyser Subscription") {
      apiEndpoint = 'https://api.paywithflash.com/geyser/api/set_geyser_user_membership_paused';
    } 
    // Default to the original endpoint
    else {
      apiEndpoint = 'https://api.paywithflash.com/api/set_user_membership_paused';
    }
    
    console.log(subscription.user_membership_details.id)
    try {
      const response = await fetch(apiEndpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: new URLSearchParams({ id: subscription.user_membership_details.id }),
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
  
      const data = await response.json();
      const currentDate = new Date().toISOString();
      // Send the subscription info via webhook
      await sendPayInfoViaWebhook (subscription.user_membership_details.flash_id, userHexPublicKey, subscription.membership_details, userName, userAbout, userPictureUrl, "user_paused_subscription", '', '', '', '');

      // After successfully pausing, call fetchSubscriptions from props
      const updatedSubscriptions = await fetchSubscriptions(userHexPublicKey);
      console.log(updatedSubscriptions);
      if (updatedSubscriptions) {
        setUpdatedSubscriptions(updatedSubscriptions);
      }
    } catch (error) {
      console.error('Error setting membership to inactive:', error);
      throw error; // Rethrow the error to be handled by the caller
    }
  }
  

// Function that calls the API to update the status of the user_subscription to "inactive"
async function handleDeleteSubscription(event, subscription) {
  event.stopPropagation();
  try {
      const response = await fetch('https://api.paywithflash.com/api/set_user_membership_inactive', {
          method: 'POST',
          headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          },
          body: new URLSearchParams({ id: subscription.user_membership_details.id }),
      });

      if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      const currentDate = new Date().toISOString();
      // Send the subscription info via webhook
      await sendPayInfoViaWebhook (subscription.user_membership_details.id, userHexPublicKey, subscription.membership_details, userName, userAbout, userPictureUrl, "user_cancelled_subscription", '', '', '', '');
      // After successfully deleting, call fetchSubscriptions from props
      const updatedSubscriptions = await fetchSubscriptions(userHexPublicKey);
      if (updatedSubscriptions) {
        setUpdatedSubscriptions(updatedSubscriptions);
      }
      
  } catch (error) {
      console.error('Error setting membership to inactive:', error);
      throw error; // Rethrow the error to be handled by the caller
  }
}


  

  function determineSubscriptionstatus(subscription) {
    const expiryDate = new Date(subscription.user_membership_details.next_payment_date);
    const now = new Date();
    return expiryDate > now ? 'active' : 'expired';
  }


  // Sort subscriptions by status
  const sortedSubscriptions = subscriptions.sort((a, b) => {
    const statusA = determineSubscriptionstatus(a);
    const statusB = determineSubscriptionstatus(b);

    if (statusA === 'active' && statusB !== 'active') return -1;
    if (statusB === 'active' && statusA !== 'active') return 1;
    // Optionally, add more conditions here if you want to further sort by other statuses
    return 0;
  });


  // useEffect hook to get the priceInSats and priceInDollars
  useEffect(() => {
    async function updateSubscriptions() {
        const newSubscriptions = await convertPrices(subscriptions);
        setUpdatedSubscriptions(newSubscriptions);
    }

    if (subscriptions.length > 0) {
      updateSubscriptions();
    } else {
      setUpdatedSubscriptions([]); // Reset if there are no subscriptions
    }

  }, [subscriptions]); // Dependency on subscriptions



  const renderFlashIcon = (flash) => {
  
    // Check if flash_image_url exists and is not empty
    if (flash.flash_image_url) {
      return (
        <span>
          <img src={flash.flash_image_url} 
              style={{ objectFit: 'contain' }} 
              className="p-2 h-12 w-12 flex-none rounded-lg bg-white object-cover ring-1 ring-gray-900/10"
              alt="Flash" />
        </span>
      );
    } else {
      return (
        <span className="h-12 w-12 flex-none rounded-lg bg-white object-cover ring-1 ring-gray-900/10 bg-indigo-50 text-indigo-700 flex items-center justify-center">
          <BsFillPersonVcardFill className="h-5 w-5" aria-hidden="true" />
        </span>
      );
    }
  };
console.log(updatedSubscriptions)
  
  return (
    <div className='p-6 border border-gray-200 bg-white rounded-xl'>
    <ul role="list" className="grid grid-cols-1 gap-x-6 gap-y-4 sm:grid-cols-2 lg:grid-cols-2 xl:gap-x-8">
      {updatedSubscriptions.map((subscription) => (
        <li key={subscription.user_membership_details.id} className="overflow-hidden rounded-xl border border-gray-200 shadow-sm">
          <div className="border-b border-gray-900/5 bg-gray-50 p-6 flex justify-between">
            <div className="flex flex-col">
              {renderFlashIcon(subscription.flash_details)}
              <div className="text-xs font-medium leading-6 text-gray-900">
                {subscription.flash_details.name}
              </div>
            </div>
            <div className="flex items-center">
              {renderStatus(subscription.user_membership_details.status)}
            </div>
          </div>

          <dl className="-my-3 divide-y divide-gray-100 px-6 py-4 text-sm leading-2">
            {/* <div className="py-3">
              <dt className="text-gray-500">Plan</dt>
              <dd className="text-gray-900">{subscription.membership_details.name}</dd>
            </div> */}
            <div className="py-3">
              <dt className="text-gray-500">Price</dt>
              <dd className="text-gray-900">
                {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(subscription.priceInDollars)}
                {' '}(<i className="fak fa-bold-italic"></i>{subscription.priceInSats})
              </dd>
            </div>
            <div className="py-3">
              <dt className="text-gray-500">{subscription.user_membership_details.is_paid === "true" ? "Next payment date" : "Expired since"}</dt>
              <dd className="text-gray-900">{subscription.user_membership_details.next_payment_date}</dd>
            </div>
          </dl>
          <div className="px-6 py-4">
            {(subscription.user_membership_details.is_paid === "true" || subscription.user_membership_details.is_paid === true) && subscription.user_membership_details.status === "active" && (
              <button
                onClick={(event) => handlePauseSubscription(event, subscription, userHexPublicKey)}
                className="mr-2 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700"
              >
                Pause Subscription
              </button>
            )}
            {(subscription.user_membership_details.is_paid === "true" || subscription.user_membership_details.is_paid === true) && subscription.user_membership_details.status === "paused" && (
              <button
                onClick={(event) => handleResumeSubscription(event, subscription)}
                className="mr-2 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700"
              >
                Resume Subscription
              </button>
            )}
            {(subscription.user_membership_details.is_paid === "false" || subscription.user_membership_details.is_paid === false) && (
              <>
                <button
                  onClick={(event) => handleRenewSubscription(event, subscription)}
                  className="mr-2 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700"
                >
                  Renew Subscription
                </button>
                <button
                  onClick={(event) => handleDeleteSubscription(event, subscription)}
                  className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-red-600 hover:bg-red-700"
                >
                  Delete Subscription
                </button>
              </>
            )}
          </div>
        </li>
      ))}
    </ul>
    </div>
  );
  
}

